
.interactive-manage {
      min-height: calc(100vh - 100px);
  }
  .editPwd{
      display: flex;
      min-height: calc(100vh - 192px);
      margin-top: 17px;
      min-height: 440px;
          background-color: #fff;
          border-radius: 10px;
          margin-right: 17px;
  }
